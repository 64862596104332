$frame-sm-margin: 15px;
$frame-margin: 20px;
$white-color: #ffff;

.gallery-modal {
  .modal-content {
    border: 0px;
    background-color: var(--gallery-background-color);
    color: var(--gallery-font-color);
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--gallery-font-color);
    }
    .form-control,
    select {
      background-color: var(--gallery-background-color-transparent-2);
      padding: 10px 20px;
      border-radius: 15px;
      border-color: var(--gallery-background-color-2)
        var(--gallery-background-color-2) var(--gallery-background-color-2)
        var(--gallery-background-color-2);
      border-width: 1px;
      color: var(--gallery-font-color);
    }

    input.form-control {
      color: var(--gallery-font-color) !important;
      &::placeholder {
        color: var(--gallery-font-color-2) !important;
        opacity: 0.4; /* Firefox */
      }
    }
    select.form-control {
      color: var(--gallery-font-color) !important;
      &::placeholder {
        color: var(--gallery-font-color-2) !important;
        opacity: 0.4; /* Firefox */
      }
    }

    input:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: var(--gallery-font-color);
    }
    .form-check-input:checked {
      border-color: var(--gallery-primary-color);
      // background-color: var(--gallery-primary-color);
    }

    .card {
      --bs-card-bg: unset;
    }
    .card,
    .card-body {
      // background-color: var(--gallery-background-color-2);
      color: var(--gallery-font-color);
    }
    .card-header {
      background-color: var(--gallery-primary-color);
      color: var(--gallery-primary-font-color);
    }
    .card-title {
      color: var(--gallery-primary-font-color);
    }
    .list-group-item {
      background-color: var(--gallery-background-color);
      color: var(--gallery-font-color);
      border-color: var(--gallery-background-color-2);
    }
  }
  .btn-soft-primary {
    color: var(--gallery-font-color);
    border-color: var(--gallery-primary-color-2);
    &:hover {
      background-color: var(--gallery-primary-color);
    }
  }
  .btn-gallery-primary {
    background-color: var(--gallery-primary-color);
    color: var(--gallery-primary-font-color);
    &:hover {
      background-color: var(--gallery-primary-color-3);
    }
  }
  .btn-gallery-outline-primary {
    border: 1px solid var(--gallery-primary-color);
    color: var(--gallery-primary-color);
    &:hover {
      background-color: var(--gallery-primary-color-3);
      color: var(--gallery-primary-font-color);
    }
  }
  .btn-gallery-soft-primary {
    background-color: transparent;
    color: var(--gallery-primary-color);
    border: 1px solid var(--gallery-primary-color);
    &:hover {
      background-color: var(--gallery-primary-color);
      color: var(--gallery-primary-font-color);
    }
  }
  .btn-soft {
    color: var(--gallery-font-color);
    background-color: var(--gallery-background-color-2);
  }
}

.client-gallery-container {
  background-color: var(--gallery-background-color);
  min-height: 100vh;
  .btn-gallery-primary {
    background-color: var(--gallery-primary-color);
    color: var(--gallery-primary-font-color);
    &:hover {
      background-color: var(--gallery-primary-color-3);
    }
  }
  .btn-gallery-outline-primary {
    border: 1px solid var(--gallery-primary-color);
    color: var(--gallery-primary-color);
    &:hover {
      background-color: var(--gallery-primary-color-3);
      color: var(--gallery-primary-font-color);
    }
  }
  .btn-gallery-soft-primary {
    background-color: transparent;
    color: var(--gallery-primary-color);
    border: 1px solid var(--gallery-primary-color);
    &:hover {
      background-color: var(--gallery-primary-color);
      color: var(--gallery-primary-font-color);
    }
  }

  .form-control {
    background-color: var(--gallery-background-color-3);
    padding: 10px 20px;
    border-radius: 15px;
    border-color: var(--gallery-background-color-2)
      var(--gallery-background-color-2) var(--gallery-background-color-2)
      var(--gallery-background-color-2);
    border-width: 1px;
  }
  .face-search-form .form-select {
    font-size: 16px;
    background-color: var(--gallery-background-color-3);
    padding: 10px 20px;
    border-radius: 15px;
    border-color: var(--gallery-background-color-2)
      var(--gallery-background-color-2) var(--gallery-background-color-2)
      var(--gallery-background-color-2);
    border-width: 1px;
    margin-top: 5px;
  }
  input.form-control {
    color: var(--gallery-font-color) !important;
    &::placeholder {
      color: var(--gallery-font-color-2) !important;
      opacity: 0.4; /* Firefox */
    }
  }
  select.form-control {
    color: var(--gallery-font-color) !important;
    &::placeholder {
      color: var(--gallery-font-color-2) !important;
      opacity: 0.4; /* Firefox */
    }
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gallery-font-color);
  }
  .form-check-input:checked {
    border-color: var(--gallery-primary-color);
    // background-color: var(--gallery-primary-color);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--gallery-font-color);
  }
  color: var(--gallery-font-color);

  .btn-soft-primary {
    color: var(--gallery-font-color);
    border-color: var(--gallery-primary-color-2);
    &:hover {
      background-color: var(--gallery-primary-color);
    }
  }
  .btn-soft {
    color: var(--gallery-font-color);
    background-color: var(--gallery-background-color-2);
    &:disabled {
      color: #9d9d9d;
      border-color: var(--gallery-background-color-2);
    }
  }
}

.client-gallery {
  background-color: var(--gallery-background-color);

  .gallery-grid-container {
    padding: 10px calc(var(--gallery-side-padding) * 1);
  }

  // @media (max-width: 767.98px) {
  //   .gallery-grid-container {
  //     padding: 0 calc(var(--gallery-side-padding) * 0.7);
  //   }
  // }

  .no-photo-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30%;
    }
  }
  .gallery-options {
    padding-right: 0;
    .dropdown {
      padding-right: 0;
    }
  }
  .btn-face-search.btn {
    border-radius: 2px;
    font-size: 0.75rem;
    padding: 5px 10px 5px 10px;
  }
  .brand-name {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .image-container {
    border-radius: 8px;

    img.react-photo-album--photo {
      border-radius: var(--gallery-image-radius);
      opacity: 1;
      transition: 0.5s ease;
    }

    .placeholder {
      border-radius: 10px;
    }

    .image-lock {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      justify-content: flex-end;
      align-items: flex-end;
      opacity: 1;
      backdrop-filter: blur(10px);
      img {
        height: 50px;
      }
      color: white;
    }
    .image-options {
      border-radius: var(--gallery-image-radius);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: flex-end;
      opacity: 1;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.282) 5%,
        rgba(247, 247, 247, 0) 12%,
        rgba(255, 255, 255, 0) 100%
      );
      // display: none;

      i {
        color: rgba(255, 255, 255, 0.9);
      }

      button.img-options-button {
        transition: opacity 0.5s ease;
        opacity: 0;
        background-color: transparent;
        border: none;

        &:disabled {
          opacity: 0.6;

          i {
            cursor: wait;
          }
        }
      }

      button.img-album-options-button {
        transition: opacity 0.5s ease;
        opacity: 1;
        background-color: transparent;
        border: none;

        &:disabled {
          opacity: 0.6;

          i {
            cursor: wait;
          }
        }
      }
    }

    &:hover {
      img {
        opacity: 0.9;
        // -webkit-filter: grayscale(10%); /* Safari 6.0 - 9.0 */
        // filter: grayscale(10%);
      }

      .image-options {
        button.img-options-button {
          opacity: 1;
        }

        background-color: rgba(0, 0, 0, 0.25);
        opacity: 1;

        i {
          &:hover {
            color: rgb(255, 255, 255);
          }
        }
      }
    }
    &.selected {
      background-color: #cddffe;

      img {
        // -webkit-transform: scale($selecton-shrink); /* Saf3.1+, Chrome */
        // -moz-transform: scale($selecton-shrink); /* FF3.5+ */
        // -ms-transform: scale($selecton-shrink); /* IE9 */
        // -o-transform: scale($selecton-shrink); /* Opera 10.5+ */
        // transform: scale($selecton-shrink);
        padding: 5px;
      }

      .image-options {
        background-color: #43a7ff78;
      }
    }
  }
  .form-control,
  .form-select {
    filter: brightness(1);
    background-color: var(--gallery-background-color);
    filter: brightness(0.9);
    border-color: #5050505f;
  }

  @media (max-width: 767.98px) {
    .image-container {
      border-radius: 5px;

      img {
        border-radius: 5px;
      }

      .placeholder {
        border-radius: 5px;
      }

      .image-options {
        opacity: 1;
        border-radius: 5px;

        button.img-options-button {
          opacity: 1;
          padding: 0 2px 0 0;
        }
      }
    }
    .brand-name {
      font-size: 0.9rem;
    }
  }

  .collection-nav {
    font-family: "Mulish", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    .nav-item {
      width: min-content;
      // overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .nav-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
      }
    }
    .nav-item.active {
      .nav-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: unset;
      }
    }

    .nav-link.active {
      background-color: unset;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: unset;
    }
  }

  .btn-face-search.btn {
    border-radius: 0;
    font-size: 0.8rem;
    padding: 5px 8px 5px 8px;
  }

  .client-gallery-toolbar {
    @media (max-width: 767.98px) {
      .btn {
        padding-right: 0px;
      }

      padding-right: 0px;
    }
  }
}

///////////// client gallery/ //////

.client-gallery-nav-bar {
  position: relative;
  top: initial;
  z-index: 100;
  width: calc(100%);
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: nowrap;
  // background: rgba(255, 255, 255, 0.7);
  background: var(--gallery-navbar-background-color);
  color: var(--gallery-navbar-color);

  // backdrop-filter: blur(5px);
  .dropdown-toggle.btn.btn-secondary {
    background-color: transparent;
    color: var(--gallery-navbar-color);
    i {
      color: var(--gallery-navbar-color);
    }
  }

  .nav-tabs {
    border-color: #ffffff31;
    .nav-link {
      color: var(--gallery-navbar-color);
      &:focus,
      &:hover {
        border-color: $gray-dark-300 $gray-dark-300 $gray-dark-300;
      }
      &.active {
        background-color: transparent;
        border-bottom: 1px solid var(--gallery-navbar-active-color);
        color: var(--gallery-navbar-active-color);
      }
      &::after {
        background: var(--gallery-navbar-active-color);
        height: 2px;
        border-bottom: 1px solid var(--gallery-navbar-active-color);
      }
    }
  }

  .nav-pills {
    .nav-link {
      color: var(--gallery-navbar-color);
      &.active {
        color: var(--gallery-navbar-active-color);
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100%);
    padding-top: 10px;
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;

    transition: top 0.5s ease;
    -webkit-transition: top 0.5s ease;
    -moz-transition: top 0.5s ease;
    -ms-transition: top 0.5s ease;
    -o-transition: top 0.5s ease;
    // background: rgba(230, 230, 230, 0.7);
    backdrop-filter: blur(5px);

    @media (min-width: 768px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .collection {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 990px) {
  .client-gallery-nav-bar {
    width: 100%;
    &.sticky {
      position: fixed;
      top: 0px;
    }
    .collection-nav {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .client-gallery {
    .no-photo-container {
      height: 60vh;
      img {
        width: 70%;
      }
    }
  }
}

.client-gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dropleft > .dropdown-menu {
  left: 0;
  transform: translate(-70%, 0) !important;
}

.dropdown-toggle.btn {
  background-color: transparent;
  border: none;

  &:hover,
  &:active,
  &:visited {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.powered-by {
  font-size: 0.65rem;
  letter-spacing: 2px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  color: $white-color;
  a {
    margin-left: 4px;
    color: $gray-700;
  }
  img {
    height: 25px;
  }
}
.gallery-footer {
  background-color: var(--gallery-footer-background-color);
}
.gallery-powered-by {
  font-size: 0.65rem;
  letter-spacing: 2px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  // background-color: var(--gallery-footer-background-color);
  color: var(--gallery-footer-color);
  a {
    margin-left: 4px;
    color: $gray-700;
  }
  img {
    height: 25px;
  }
}

.fotoowl-top {
  z-index: 1000;
  position: absolute;
  top: calc(#{$frame-margin} + 5px);
  left: calc(#{$frame-margin} + 5px);
  padding: 10px;

  img {
    height: 30px;
    width: auto;
  }

  @media screen and (max-width: 450px) {
    top: calc(#{$frame-sm-margin} + 5px);
    left: calc(#{$frame-sm-margin} + 5px);
  }
}

.fotoowl-floating {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 8px;
  background-color: #0f0f0fd0;
  width: 100%;
  font-size: 8px;
  color: $white;
  img {
    height: 15px;
    width: auto;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body[data-layout-mode="dark"] {
  .client-gallery-nav-bar {
    .nav-link.active {
      background-color: unset;
    }
  }

  .dropdown-toggle.btn {
    i {
      color: $white;
    }
  }

  .powered-by {
    color: $white;
    img {
      height: 25px;
    }
  }
}

.liked {
  // color: $red !important;
  color: var(--gallery-primary-color) !important;
}

////////// LIGHT BOX////////////
///
///

.video-container {
  width: 100%;
  display: flex;
  justify-content: center;

  video,
  iframe {
    width: 80%;
    border-radius: 10px;
  }
}

@media screen and (max-width: 990px) {
  .video-container {
    video,
    iframe {
      width: 100%;
      border-radius: 5px;
    }
  }
}

.gallery-options.dropleft {
  .dropdown-menu {
    top: 0;
    // left: -100px !important;
    left: auto !important;
    right: 0px !important;
  }
}

.placeholder-glow {
  .placeholder {
    animation: placeholder-glow 1.3s ease-in-out infinite;
  }
}

@keyframes placeholder-glow {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
}

.face-recognition {
  animation: grow 1s ease-in-out 0s 10;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.helper-message {
  margin-top: 20px;
}

.helper-resolution {
  background-color: $gray-200;
  padding: 50px;
  border-radius: 8px;
}

@media screen and (max-width: 990px) {
  .helper-resolution {
    padding: 20px;
  }
}

body[data-layout-mode="dark"] {
  .helper-resolution {
    background-color: $gray-dark-200;
    color: $white;
  }
  .client-gallery-nav-bar.sticky {
    background: rgba(39, 39, 39, 0.6);
    backdrop-filter: blur(5px);
  }
  .client-gallery-nav-bar {
    background: rgba(39, 39, 39, 0.6);
    backdrop-filter: blur(5px);
  }
}

.inquiry-modal {
  // .modal-content {
  //   background-color: #f8f9fa;
  // }

  .cross-close {
    position: absolute;
    right: 1%;
    top: 1%;
    font-size: 25px;
  }

  .contact-icon {
    border-radius: 20px;
    border: 1px solid black;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  Label {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .contact-us {
    font-size: 22px;
  }

  .code {
    max-width: 80px;
  }

  small {
    margin-left: 80px;
  }

  .modal-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 5px;
    padding-top: 5px;
    overflow-y: auto;
  }

  .modal-footer {
    padding: 1px;
  }

  .contact-us-logo {
    width: 100px;
    height: auto;
  }

  @media (max-width: 576px) {
    .modal-body {
      padding-left: 4px;
      padding-right: 4px;
    }

    .inquiry-illustration {
      display: none;
    }

    .form-group {
      margin-bottom: 2px;
      max-width: 100%;
    }

    .contact-us {
      font-size: 15px;
    }

    .contact-us-logo {
      width: 70px;
    }
  }
}

.client-gallery {
  .switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
  }

  label.switch {
    margin-bottom: 0;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c3c3c3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    text-align: center;
    white-space: pre-line;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(35px);
  }

  /*------ ADDED CSS ---------*/
  .slider:after {
    content: "My Photos";
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 65%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }

  input:checked + .slider:after {
    content: "All Photos";
    left: 35%;
  }
}

.swal-text {
  text-align: center;
}

/*--------- END --------*/

.gallery-dropdown {
  .dropstart {
    .dropdown-menu {
      transform: translate(-80%, 0) !important;
    }
  }
}

.PhoneInput {
  .form-group {
    width: 100%;
  }
}

/// client gallery control classes
.client-gallery {
  .client-gallery-nav-bar {
    .inquiry {
      display: var(--show-cover-inquiry) !important;
    }
  }
}

// react album

.blink {
  color: rgb(0, 107, 119);
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    color: rgb(0, 140, 156);
  }
  50% {
    color: rgb(1, 217, 228);
    transform: scale(1.05);
  }
  100% {
    color: rgb(0, 140, 156);
    transform: scale(1);
  }
}

.blink-warning {
  color: rgb(248, 32, 32);
  animation: blinkwarning 1s linear infinite;
}
@keyframes blinkwarning {
  0% {
    color: rgb(248, 32, 32);
  }
  50% {
    color: rgb(248, 32, 32);
    transform: scale(1.05);
  }
  100% {
    color: rgb(248, 32, 32);
    transform: scale(1);
  }
}

.my-upload-toolbar {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.804);
  width: 200px;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  border-radius: 10px;
  color: $white-color;
  padding: 10px 10px;
  font-size: 15px;
}
